async function postData(url = '', data = {}) {
    try {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'same-origin',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
        return response.json();
    } catch (e) {
        if (window.tellMe?.log) {
            window.tellMe.log.fatalException("collect error", e);
        } else {
            console.error(e);
        }
    }
}


class Stats {

    constructor(container) {
        this.container = container;
        this.onClick = this.onClick.bind(this);
        this.collect = this.collect.bind(this);
        this.init();
    }


    init() {
        this.actions = [...this.container.querySelectorAll('*[data-collect]')];

        this.actions.forEach((item) => {
            item.addEventListener('click', this.onClick, { passive: true, capture: true });
        });

        this.content = this.container.querySelector('span[data-collect="content"]');

        if (this.content) {
            this.collect(this.content.dataset.target, this.content.dataset.targetId, 'loaded');
        }
    }

    onClick(e) {
        const item = e.target;
        const action = item.dataset.collect ?? '';
        const data = item.dataset.collectData ?? null;

        this.collect(item.dataset.target ?? '', item.dataset.targetId ?? '', action), data;
    }

    collect(target, targetId, action, data, reset) {
        if (window.collectUrl) {
            postData(collectUrl, {
                target,
                targetId,
                action,
                data,
                reset
            });
        }
    }


}

export default Stats;