
const storageKey = (runId) => {
    if (!runId) { runId = '_'; }
    return `Run[${runId}]`;
};

const getRunData = (runId) => {
    const dataString = window.localStorage.getItem(storageKey(runId));
    let result = {};
    if (dataString !== null) {
        try {
            result = JSON.parse(dataString);
        } catch (e) {
            result = {};
        }
    }
    return result;
}

const get = (key, runId) => {
    let data = getRunData(runId);
    let result = {};

    if (data.hasOwnProperty(key)) {
        result = data[key];
    }

    return result;
}

const set = (key, value, runId) => {
    let current = getRunData(runId);
    current[key] = value;
    window.localStorage.setItem(storageKey(runId), JSON.stringify(current));
};

export default {
    get: get,
    set: set
}